import { Column, Pie, type PieOptions, type ColumnOptions } from '@antv/g2plot';
import { useDeepCompareEffect } from 'ahooks';
import { memo, useRef } from 'react';
import { useGlobalMyInfo } from '../myInfo/useGlobalMyInfo';

interface propType extends PieOptions {
	height: number;
}

export const PlotContainer = (props: propType) => {
	const container = useRef(null);
	const ins = useRef<Pie | null>(null);

	const [stateParams] = useGlobalMyInfo();
	const isShowLabel =
		stateParams?.globalSensitiveInformationHiding === false ?? true;

	const shouldData = props.data.map((a) => {
		a.value = a.value?.toNumber ? a.value.toNumber() : a.value;

		return a;
	});

	useDeepCompareEffect(() => {
		if (!shouldData) {
			return;
		}

		if (ins.current) {
			ins.current?.changeData(shouldData);

			// ins.current.render();
			return;
		}

		if (!container.current) {
			return;
		}

		const l = new Pie(container.current, {
			...props,
		});

		ins.current = l;
		l.render();
	}, [props.data]);

	return (
		<div
			className="w-full"
			style={{
				height: props.height,
			}}
			ref={container}
		/>
	);
};
